//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-576:_9591,_4172,_9196,_368,_308,_1808,_5913,_1868;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-576')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-576', "_9591,_4172,_9196,_368,_308,_1808,_5913,_1868");
        }
      }catch (ex) {
        console.error(ex);
      }